import type { EventProperties } from '@segment/analytics-next';
import { createContext, type ReactNode, useContext } from 'react';

const ExtraPropertiesContext = createContext<EventProperties | null>(null);

export const ExtraAnalyticsPropertiesProvider = ({
  children,
  ...extraProperties
}: { children: ReactNode } & EventProperties) => {
  const previousExtraProperties = useContext(ExtraPropertiesContext);

  return (
    <ExtraPropertiesContext.Provider
      value={{
        ...previousExtraProperties,
        ...extraProperties,
      }}
    >
      {children}
    </ExtraPropertiesContext.Provider>
  );
};

export function useExtraProperties() {
  const extraProperties = useContext(ExtraPropertiesContext);
  return extraProperties;
}
